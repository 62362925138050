<div
  *transloco="let t"
  #sectionId_about
  id="sectionId_about"
  class="sectionId_about flexBoxOuter"
  style="padding: 50px 20px; background: #fdfdfe"
>
  <div class="flexBoxInner txt marginRight align-inner">
    <div class="">
      <div>
        <mat-card-title class="keevalue-title" style="margin-bottom: 10px">
          {{ t("products.start.quickNav.about") }}
        </mat-card-title>
      </div>
      <div style="margin-bottom: 20px">
        <span class="logo-black" style="height: 33px">
          <img style="height: 35px; width: auto" src="assets/images/logo_kennwerte.svg" alt="logo" />
          <span>keeValue.ch</span>
        </span>
      </div>
      <div class="baukosten-box">
        <p class="brightfont" [innerHtml]="t('products.about.text-1-1')"></p>
        <p class="brightfont hyphen mt-3">{{ t("products.about.text-1-2") }}</p>
        <p class="brightfont hyphen mt-3">
          <span>{{ t("products.about.text-2-1") }}</span>
          <a href="mailto:hunziker@keevalue.ch">{{ t("products.about.text-2-2") }} </a>
          <span>{{ t("products.about.text-2-3") }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="flexBoxInner img">
    <div class="center-img-box">
      <div>
        <a href="https://www.sia.ch/" target="_blank">
          <img src="assets/images/partners/SIA_links.svg" height="76" />
        </a>
      </div>
      <div class="mt-5">
        <a
          class="mt-5"
          href="https://www.hightechzentrum.ch/projekte/35-innovationsberatung/551-kennwerte-ag-brugg.html"
          target="_blank"
        >
          <img src="assets/images/partners/Hightech_zentrum_Aargau_Logo.svg" height="45" />
        </a>
      </div>
      <div class="mt-5">
        <img src="assets/images/partners/DE_Innosuisse_Logo_Original.svg" height="76" />
      </div>
      <div class="mt-5">
        <a href="https://www.fhnw.ch/de/die-fhnw/hochschulen/ht/institute/forschungsprojekte/kennwerte" target="_blank">
          <img src="assets/images/partners/FHNW_HT_10mm.svg" height="32" />
        </a>
      </div>
      <div class="mt-5">
        <a href="https://www.hslu.ch/de-ch/" target="_blank">
          <img src="assets/images/partners/hslu_2.svg" height="32" />
        </a>
      </div>
    </div>
  </div>
</div>
