import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedDigitComponent } from './animated-digit.component';
import { IsVisibleDirective } from '@app/core/directives/is-visible.directive';

@NgModule({
  declarations: [AnimatedDigitComponent],
  imports: [CommonModule, IsVisibleDirective],
  exports: [AnimatedDigitComponent],
})
export class AnimatedDigitModule {}
